import { cva } from "class-variance-authority";

export const inputVariants = cva(
  "border py-3 px-4 focus:outline-none bg-transparent w-full h-12",
  {
    variants: {
      invalid: {
        true: "border-red-600 focus:border-red-600 ring-1 ring-red-500 focus:ring-red-600 text-red-700 focus:text-red-800",
        false: "focus:border-primary focus:ring-1 focus:ring-primary",
      },
      disabled: {
        true: "text-on-surface/30 cursor-not-allowed border-outline/30",
      },
      position: {
        start: "rounded-l-lg",
        end: "rounded-r-lg",
        center: "rounded-none",
        isolated: "rounded-lg",
      },
    },
    compoundVariants: [
      {
        invalid: false,
        disabled: false,
        class: "border-outline",
      },
    ],
    defaultVariants: {
      invalid: false,
      disabled: false,
      position: "isolated",
    },
  },
);
